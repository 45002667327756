<template>
  <div class="af-panel" v-if="visible">
    <div class="af-panel__text mb-4">
      <b-icon
        v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
        icon-pack="fa"
        icon="smile"
        size="is-large"
        type="is-green"
        class="mr-2"
      ></b-icon>

      <div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center"
        >
          <p class="is-size-5 has-text-weight-bold mb-4">
            {{ $t("af:offers.eligible_for_more.title") }}
          </p>
          <div @click="visible = false" class="mb-4 cursor-pointer">
            <b-icon
              icon-pack="fas"
              icon="times"
              size="is-medium"
              type="is-grey"
            />
          </div>
        </div>

        <span>
          {{
            $t("af:offers.eligible_for_more.subtitle", {
              amount: monify(dtiCalc.amount),
            })
          }}
        </span>
      </div>
    </div>

    <div class="af-panel__actions">
      <b-button type="is-text" class="has-text-weight-medium" @click="recalc"
        >{{
          $t("af:offers.eligible_for_more.button.show_me", {
            amount: monify(dtiCalc.amount),
          })
        }}
      </b-button>

      <span
        v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
        class="af-text-button has-text-grey-icon"
        @click="visible = false"
      >
        {{ $t("af:offers.eligible_for_more.button.not_interested") }}
      </span>
    </div>
  </div>
</template>

<script>
import { isBrand, monify } from "@/utils/util";
import { CUSTOM_APPEARANCE } from "@/utils/const";

export default {
  name: "EligibleForMoreLoan",
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
  },
  data() {
    return {
      monify,
      visible: true,
    };
  },
  props: {
    dtiCalc: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isBrand,
    recalc() {
      this.$emit("recalc");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-panel {
  border-radius: 12px;
  padding: 20px;
  background: $green-1;
  border: 1px solid $green-2;

  &__text {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &__actions {
    button,
    span {
      width: 100%;
      font-size: 0.8rem;
    }
  }
}

.mbh {
  .af-panel {
    border-radius: 12px;
    padding: 32px;
    background: white;
    border: none;
  }
}

@media only screen and (min-width: 769px) {
  .af-panel {
    padding: 32px 36px;

    &__actions {
      button,
      span {
        width: unset;
        font-size: initial;
      }
    }
  }
}
</style>
